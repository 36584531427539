import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero-our-model"
import TheScienceOfCodingContent from "../../components/the-science-of-coding-content"


const TheScienceOfCodingPage = () => (
  <Layout>
    <SEO title="The Science of Coding" />
    <TheScienceOfCodingContent />
  </Layout>
)

export default TheScienceOfCodingPage