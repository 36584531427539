import React from 'react';
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

const Section = styled.section`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    max-width: 100%;
    // background-color: #000;
    // background-color: rgba(234,241,243,0.85);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-top: -131px;
`

const TextWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 1372px;
    max-width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 70px;
    color: white;
`

const Text = styled.h3`
    font-size: 40px;
    width: 1180px;
    max-width: 90%;
    line-height: 1.2;
    margin-bottom: 30px;
    font-weight: 400;
    
`

const Bold = styled.span`
    font-weight: 700;
`


const HeroOurModel = () => {
    const data = useStaticQuery(graphql`
        query {
          file(relativePath: {eq: "AdobeStock_261530621_Preview-original.jpg"}) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
    `)
    
    return (
        <Section>
            <Img
                fluid={data.file.childImageSharp.fluid}
                alt="CodeBreakers image"
                style={{zIndex: -1, height: '100vh', minHeight: 600}}
                
            />
            <TextWrapper>
                <Text>Nunc felis dui, dictum sed eros ac, elementum malesuada sapien</Text>
                <Text>Mauris elementum ante urna, ultricies pharetra metus volutpat id</Text>
            </TextWrapper>
        </Section>
    )
}

export default HeroOurModel;